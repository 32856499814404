var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutCompact',{staticClass:"hide-nav-left-pc"},[_c('div',{staticClass:"reset-password-page"},[(_vm.image)?_c('div',{staticClass:"img-reset-password pt-10"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.image,"alt":"reset-password logo"}})]):_vm._e(),_c('p',{staticClass:"text-dark text-center mx-auto fs-23 fs-xl-26 my-5 mw-xl-555 fwb"},[_vm._v(" パスワード再設定画面 ")]),_c('p',{staticClass:"text-dark text-center mx-auto fs-17 fs-xl-18 mw-xl-600"},[_vm._v(" パスワードを入力し、"),_c('br',{staticClass:"d-xl-none"}),_vm._v(" 送信ボタンを押して下さい。 ")]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"mx-auto"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.handleResetPassword)}}},[_c('div',{staticClass:"form-group mx-auto fs-20 mt-8 mb-3 mw-270 mw-xl-670 mt-xl-0"},[_c('div',{staticClass:"position-relative"},[_c('ValidationProvider',{attrs:{"vid":"checkPassword","name":"password","rules":{
                    required: true,
                    min: 9,
                    regex: ("" + _vm.pattern)
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var reset = ref.reset;
return [(errors[0])?_c('div',{staticClass:"alert-tooltip-block",on:{"click":reset}},[_c('div',{staticClass:"alert alert-tooltip alert-black text-light fs-14 mb-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"form-control form-control-xl-h-45 text-center fs-xl-18 rounded-1",attrs:{"type":"password","placeholder":"新しいパスワード"},domProps:{"value":(_vm.user.password)},on:{"click":reset,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}})]}}],null,true)})],1)]),_c('p',{staticClass:"fs-10 mx-auto mw-345 mw-xl-690 fs-xl-10"},[_vm._v(" ※アルファベットの大文字・小文字を必ず1文字以上使用し、"),_c('br'),_vm._v(" かつ数字か記号 を組み合わせて9文字以上で入力してください"),_c('br'),_vm._v(" ※全角英数字、半角記号「¥、&、<、>、”、’、（半角スペース）」は"),_c('br',{staticClass:"d-xl-none"}),_vm._v(" 使用できません"),_c('br'),_vm._v(" ※前パスワードと同じものは使用できません ")]),_c('div',{staticClass:"form-group mx-auto fs-20 mw-270 mw-xl-670 mt-9 mb-8 mt-xl-0 "},[_c('div',{staticClass:"position-relative"},[_c('ValidationProvider',{attrs:{"name":"passwordConfirmation","rules":"required|confirmed:checkPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var reset = ref.reset;
return [(errors[0])?_c('div',{staticClass:"alert-tooltip-block",on:{"click":reset}},[_c('div',{staticClass:"alert alert-tooltip alert-black text-light fs-14 mb-0"},[_vm._v(" "+_vm._s(errors[0])+" ")])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password_confirmation),expression:"user.password_confirmation"}],staticClass:"form-control form-control-xl-h-45 text-center fs-xl-18 rounded-1",attrs:{"type":"password","placeholder":"新しいパスワード（確認）"},domProps:{"value":(_vm.user.password_confirmation)},on:{"click":reset,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password_confirmation", $event.target.value)}}})]}}],null,true)})],1)]),(_vm.message)?_c('p',{staticClass:"text text-danger text-center fs-14 fs-xl-18"},[_vm._v(" メールアドレスとパスワードを同じにすることはできません。 ")]):_vm._e(),(_vm.messagePassOld)?_c('p',{staticClass:"text text-danger text-center fs-14 fs-xl-18"},[_vm._v(" 以前のパスワードと同じようです。新しいパスワードを入力してください。 ")]):_vm._e(),_c('button',{staticClass:"btn btn-lg btn-primary btn-block rounded-1 mx-auto fs-20 mw-270 mw-xl-670 mb-20"},[_vm._v(" 送信 ")])])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }