






















































































































import { Component, Vue } from 'vue-property-decorator'
import { Validator, ValidationProvider, ValidationObserver } from 'vee-validate'
import PasswordService from '@/services/PasswordService'
import LogoService from '@/services/LogoService'
import LayoutCompact from '@/layouts/LayoutCompact.vue'

const dict = {
  custom: {
    password: {
      required: () => 'パスワードを入力してください。',
      min: () => 'パスワードは最低9文字以上である必要があります。',
      regex: () => 'パスワードに無効な文字が含まれている。'
    },
    passwordConfirmation: {
      required: () => 'パスワードを入力してください。',
      confirmed: () => 'パスワードが正しくありません。'
    }
  }
}
Validator.localize('jp', dict)

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    LayoutCompact
  }
})
export default class ResetPassword extends Vue {
  public image: any = ''
  public pattern: string = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?!.*[¥&<>"'])[a-zA-Z\\d@$!%*?,./]+$`
  public message: boolean = false
  public messagePassOld: boolean = false

  private user: any = { password: '', password_confirmation: '' }

  async created() {
    await LogoService.getAvatar().then((res: any) => {
      this.image = res.data.items
    })
  }

  public handleResetPassword() {
    if (this.user.password && this.user.password_confirmation) {
      PasswordService.resetPassword(
        this.user.password,
        this.user.password_confirmation
      ).then(
        (response: any) => {
          if (response.data.status) {
            this.$router.push({ name: 'reset-password-success' })
          }
        },
        (error: any) => {
          if (error) {
            if (error.response.status === 429) {
              localStorage.clear()
              this.$bvModal.show(`too-many-request`)
            }
          }
          if (error.response.status === 440) {
            if (
              error.response.data.message[0] == 'CANNOT_USE_PREVIOUS_PASSWORD'
            ) {
              this.messagePassOld = true
            } else if (
              error.response.data.message[0] ==
              'PASSWORD_AND_EMAIL_CAN_NOT_SAME'
            ) {
              this.message = true
            }
          }
        }
      )
    }
  }
}
